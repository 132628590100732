
::v-deep .table-control {
    position: relative;
    z-index: 5;
    width: 100%;

    &>p {
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
    }

    table {
        margin-top: 5px;
        border: 2px dashed #AAC7CF;
        border-radius: 20px;
        border-collapse: collapse;
        border-style: hidden;
        box-shadow: 0 0 0 2px #AAC7CF;
        overflow: hidden;
        width: 100%;
    }

    td,
    th {
        text-align: center;
        border: 1px dashed #AAC7CF;
        background-color: #ffffff;
        border-collapse: separate;
        height: 60px;
        font-size: 18px;
    }

    table td {
        height: 80px;
        padding: 10px 30px;
        background-color: #ffffff;

        p {
            margin: 0;
        }
    }

    &.thrid-table {
        table {
            tr {
                &:nth-child(even) {
                    td {
                        background: #ccdafc;
                    }
                }

                &:nth-child(odd) {
                    td {
                        background: #ffffff;
                    }
                }

                th {
                    background: #ffffff;
                }
            }
        }
    }

    &.fourth-table {
        table {
            tr {
                &:nth-child(odd) {
                    td {
                        background: #ffceb6;
                    }
                }

                &:nth-child(even) {
                    td {
                        background: #ffffff;
                    }
                }

                th {
                    background: #ffffff;
                }
            }
        }
    }
}

.task-kefu-container {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-height: 950px;
    background: url('../../../../assets/images/taskdetail.png') no-repeat;
    box-sizing: border-box;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFB974;
    padding-bottom: 30px;
}

.task-container {
    width: 100%;
    height: 100%;
    min-height: 950px;
    background: url('../../../../assets/images/taskdetail.png') no-repeat;
    box-sizing: border-box;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFB974;
    padding-bottom: 30px;
}

.task-main {
    .task-box {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-top: 40px;

        .tag {
            height: 79px;
            width: 79px;
            position: absolute;
            left: -3%;
            top: 5.5%;
            background: url('../../../../assets/images/task_tag.png') no-repeat;
            z-index: 9;
        }

        .title {
            width: 397px;
            min-height: 60px;
            background: url('../../../../assets/images/taskrectangle.png') no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }
        .fz-24{
            font-size: 24px;
        }

        .context-box {
            width: 100%;
            height: 100%;
            min-height: 150px;
            background: linear-gradient(180deg, #FFFFFF 0%, #FFFDEB 100%);
            box-shadow: 0px 10px 30px 0px rgba(247, 162, 162, 0.62);
            border-radius: 20px;
            padding: 20px;

            .dashed-box {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                min-height: 150px;
                border: 1px dashed #AAC7CF;

                span {
                    padding: 20px;
                    display: block;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 20px;
                    color: #DC3D21;
                }
            }

            .thrid-table {

                td,
                th {
                    text-align: center;
                    border: 1px dashed #AAC7CF;
                    background-color: #ffffff;
                    border-collapse: separate;
                    height: 60px;
                    font-size: 18px;
                }

                table td {
                    height: 80px;
                    padding: 10px 30px;
                    background-color: #ffffff;

                    p {
                        margin: 0;
                    }
                }

                table {
                    tr {
                        &:nth-child(even) {
                            td {
                                background: #ccdafc;
                            }
                        }

                        &:nth-child(odd) {
                            td {
                                background: #ffffff;
                            }
                        }

                        th {
                            background: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
